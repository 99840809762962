<template>
    <div class="ask-video">
        <div class="video-icon icon-otw_icon_phone"></div>
        <p class="ask-text">{{`Vous avez reçu une demande de chat vidéo de ${userName}`}}</p>
        <div class="btns-container">
            <div class="btn btn-refused" @click="$emit('refuseCall', video)">Refuser</div>
            <div class="btn btn-accepted" @click="$emit('acceptCall', video)">Accepter</div>
        </div>
    </div>
</template>

<script>
export default {
    name : "AskVideo",
    props : ["video"],
    data() {
        return {
            userName : null
        }
    },
    mounted() {
        const user = this.$store.state.ATTENDEES_BDD.find(e => e.uid == this.video.sender);
        this.userName = user.firstname + " " + user.lastname;
    }
}
</script>

<style scoped lang="scss">
    .ask-video {
        position:fixed;
        top:calc(50% - 90px);
        left:calc(50% - 195px);
        background:#1a1a1a;
        border-radius:10px;
        padding:20px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width:350px;
        height:180px;
        max-width: calc(100% - 40px);
    }

    .video-icon {
        color:#fff;
        font-size:35px;
    }

    p {
        text-align: center;
        margin:20px 0;
        color:#fff;
        line-height:20px;
    }

    .btns-container {
        display: flex;
        justify-content: space-around;
        width:100%;
    }

    .btn {
        height:35px;
        width:150px;
        display: flex;
        justify-content: center;
        align-items: center;
        color:#fff;
        border-radius:20px;
        background: $succes;
        cursor: pointer;
        transition:0.5s;
    }

    .btn.btn-refused {
        background:$red;
    }

    .btn:hover {
        background: rgb(3, 70, 3);
    }

    .btn.btn-refused:hover {
        background:rgb(105, 3, 3);
    }

    @media screen and (max-width:450px) {
        .ask-video {
            width:300px;
            left:calc(50% - 170px);
        }

        .btn {
            width:120px;
        }
    }
</style>