<template>
    <div class="video-chat">
        <div class="video-chat-close icon-otw_icon_close" @click="closeVideo()"></div>
        <template v-if="this.$store.state.VIDEO.process">
            <div class="video-dest">
                <loader></loader>
                <video ref="remoteCamera" autoplay></video>
            </div>
            <div class="video-sender">
                <div class="err-my-cam" v-if="errMyCam">Impossible de récupérer votre outil de diffusion.</div>
                <video ref="myCamera" :width="220" :height="180" autoplay muted v-else></video>
            </div>
        </template>
        <template v-else>
            <div class="video-waiting">En attente de l'acceptation du destinataire.</div>
        </template>
    </div>
</template>

<script>
import Loader from "./../Loader.vue";

export default {
    name : "VideoChat",
    components : {
        Loader
    },
    data() {
        return {
            errMyCam : false,
        }
    },
    methods : {
        closeVideo() {
            const user = this.$store.state.USER.id == this.$store.state.VIDEO.sender ? this.$store.state.VIDEO.reciever : this.$store.state.VIDEO.sender;
            const video  = {
                video : this.$store.state.VIDEO,
                id : user
            }
            console.log('video store => ', this.$store.state.VIDEO)
            if(!this.$store.state.VIDEO.process) {
                const msg = {
                    typeMsg : 2,
                    txtMsg : 'Missing call',
                    roomID : this.$store.state.VIDEO.roomID,
                    sender : this.$store.state.VIDEO.sender,
                    reciever : this.$store.state.VIDEO.reciever
                }
                this.$socket.emit("sendMessage", msg);
            } 
            this.$socket.emit('closeVideo', video);
            this.$store.commit('closeVideo');  

            this.stopCameraStream(); 
  
        },
        createCameraElement() {
            this.errMyCam = false;
            const constraints = (window.constraints = {
                audio:true,
                video: true
            });

            navigator.mediaDevices
                .getUserMedia(constraints)
                .then(stream => {
                    this.$refs.myCamera.srcObject = stream;
                    console.log('start my cam');
                })
                .catch(error => {
                    console.log('err cam => ', error);
                   this.errMyCam = true;
                });
        },
        stopCameraStream() {
            let tracks = this.$refs.myCamera.srcObject.getTracks();

            tracks.forEach(track => {
                track.stop();
            });
        }
    },
    mounted() {
        if(this.$store.state.VIDEO.process) {
            this.createCameraElement();
        }

    },
    watch : {
        '$store.state.VIDEO.process' : function(newVal) {
            if(newVal) {
                this.createCameraElement();
       
            }else {
                this.stopCameraStream();
            }
        }
    }
}
</script>

<style lang="scss" scoped>
    .video-chat {
        position:fixed;
        height:80vh;
        width:80%;
        background:#000;
        top:10vh;
        left:10%;
        z-index:9999;
                display: flex;
        align-items: center;
        justify-content: center;
        color:#fff;
    }

    .video-chat-close{
        position:absolute;
        top:10px;
        right:10px;
        color:#fff;
        font-size:25px;
        cursor: pointer;
        z-index:5;
    }

    .video-waiting {
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        font-style: italic;
        color:#fff;
    }

    .video-dest {
        position:absolute;
        top:0;
        bottom:0;
        left:0;
        right:0;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .video-sender {
        position:absolute;
        bottom:10px;
        right:10px;
        width:220px;
        height:150px;
        display: flex;
        justify-content: center;
        align-items: center;
        background:#ddd;
    }

    .err-my-cam {
        margin:0 10px;
        font-size:13px;
        font-style: italic;
        text-align: center;
        color:#1a1a1a;
    }

    @media screen and (max-width : 768px){
        .video-chat {
            width:90%;
            left:5%;
        }
    }
</style>