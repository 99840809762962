<template>
    <div class="notif-chat">
        <div class="notif-icon icon-otw_icon_bulb"></div>
        <p class="notif-chat-container">
            {{ this.$store.state.NOTIF }}
        </p>
        <div class="btn-container">
            <div class="btn" @click="closeNotif()">OK</div>
        </div>
    </div>
</template>

<script>
export default {
    name : "NotifChat",
    methods : {
        closeNotif() {
            this.$store.commit('closeNotif');
        }
    }
}
</script>

<style lang="scss" scoped>
    .notif-chat {
        position:fixed;
        top:calc(50% - 90px);
        left:calc(50% - 175px);
        background:#1a1a1a;
        border-radius:10px;
        padding:20px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width:350px;
        height:200px;
        max-width: 100%;
        z-index:9;
    }

    .notif-icon {
        color:#fff;
        font-size:35px;    }

    p {
        text-align: center;
        margin:20px 0;
        color:#fff;
        line-height:20px;
    }

    .btn-container {
        display: flex;
        justify-content: center;
        width:100%;
    }

    .btn {
        height:35px;
        width:150px;
        display: flex;
        justify-content: center;
        align-items: center;
        color:#1a1a1a;
        border-radius:20px;
        background: #fff;
        cursor: pointer;
        transition:0.5s;
    }

    .btn:hover {
        background:#ddd;
    }

       @media screen and (max-width:450px) {
        .notif-chat {
            width:300px;
            left:calc(50% - 170px);
        }

        .btn {
            width:120px;
        }
       }

</style>