<template>
    <div class="loader-otw">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
    </div>
</template>

<script>
export default {
    name: 'Loader'
}
</script>

<style lang="scss" scoped>
    .loader-otw {
        display: inline-block;
        position: relative;
        width: 80px;
        height: 80px;
    }
    .loader-otw div {
        box-sizing: border-box;
        display: block;
        position: absolute;
        width: 64px;
        height: 64px;
        margin: 8px;
        border: 8px solid #fff;
        border-radius: 50%;
        animation: loader-turn 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
        border-color: #fff transparent transparent transparent;
    }
    .loader-otw div:nth-child(1) {
        animation-delay: -0.45s;
    }
    .loader-otw div:nth-child(2) {
        animation-delay: -0.3s;
    }
    .loader-otw div:nth-child(3) {
        animation-delay: -0.15s;
    }
    @keyframes loader-turn {
        0% {
            transform: rotate(0deg);
        }
        100% {
            transform: rotate(360deg);
        }
    }
</style>